/* eslint-disable @nx/enforce-module-boundaries */
import {
  HStack,
  Heading,
  Stack,
  VStack,
  Radio,
  Text,
  Box,
  Button,
  RadioGroup,
} from '@mybridge/ui';
import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCompanyPages } from 'v4/store/actions/companyPages.actions';
import PublishUserCompanyCard from './publish-card';
import SectionLoader from 'v4/components/common/loader/sectionLoader';
import { StackDivider } from '@chakra-ui/react';
import {
  IndividualArticleIcon,
  MyBridgeArticleIcon,
  MyBridgeMewsIcon,
} from '@mybridge/icons';
import { PostFormContext } from 'v4/components/post-form/context';
import { useRouter } from 'next/router';
import {
  setPublishingToData,
  setPublishingAsData,
} from 'v4/store/slices/blogSlice';
import { currentProfile } from 'v4/store/actions/user-profile.actions';

const PublishAsComponent = ({ onClose, isPageComp }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { publishArticleDisc, companyPosting } = useContext(PostFormContext);
  const { companyPageList, loading } = useSelector(
    (state) => state.companyPage
  );
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { publishingToData, publishingAsData } = useSelector(
    (state) => state.blog
  );
  const [selectedUser, setSelectedUser] = useState(userProfileInfo?.id);
  const [publishAs, setPublishAs] = useState(userProfileInfo?.firstName);
  const [publishAsDetails, setPublishAsDetails] = useState();
  const [comapanies, setComapanies] = useState();



  const [publishTo, setPublishTo] = useState('1');

  useEffect(() => {
    dispatch(getAllCompanyPages());
  }, []);

  useEffect(() => {
    if (publishingAsData) {
      setSelectedUser(publishingAsData);
    }
    if (publishingToData) {
      setPublishTo(publishingToData);
    }
  }, [publishingAsData, publishingToData]);

  const handleToChange = (e) => {
    setPublishTo(e);
    dispatch(setPublishingToData(e));
  };

  const handleNextStep = () => {
    publishArticleDisc.onClose();
    router.push('/articles/create');
  };

  const handleChangeArticle = (e, company) => {
    if (!company) {
      // dispatch(currentProfile(userProfileInfo?.[0]?.name?.[0]));
      setPublishAs(userProfileInfo?.[0]?.name?.[0]);

      // companyPosting(userProfileInfo?.first_name);
      setSelectedUser(e);
      // dispatch(setPublishingAsData(e));
      setComapanies(null)

      setPublishAsDetails(e)
    } else {
      // console.log(company, 'eeeeeeeeeesssssss');
      setPublishAs(company?.name ?? userProfileInfo?.firstName);
      // dispatch(currentProfile(company));
      setSelectedUser(e);
      dispatch(setPublishingAsData(e));
      // companyPosting(company);
      setComapanies(company)

      setPublishAsDetails(e)
    }
  };



  const handleSave=()=>{
    if (!comapanies) {
      dispatch(currentProfile(userProfileInfo?.[0]?.name?.[0]));
      setPublishAs(userProfileInfo?.[0]?.name?.[0]);

      companyPosting(userProfileInfo?.first_name);
      setSelectedUser(publishAsDetails);
      dispatch(setPublishingAsData(publishAsDetails));

      // setPublishAsDetails(e)
      // setComapanies(comapanies)
    } else {
      console.log(comapanies, 'eeeeeeeeeesssssss');
      setPublishAs(comapanies?.name ?? userProfileInfo?.firstName);
      dispatch(currentProfile(comapanies));
      setSelectedUser(publishAsDetails);
      dispatch(setPublishingAsData(publishAsDetails));
      companyPosting(comapanies);
      // setPublishAsDetails(e)
    }
    onClose()
  }
  console.log(publishAsDetails,comapanies,"publishAsDetails")

  // const selectedProfile = companyPageList.filter(
  //   (details) => details?.id == selectedUser
  // );
  // dispatch(currentProfile(selectedProfile?.[0]));
  // setOpenPostModal(false);
  console.log(userProfileInfo, 'userProfileInfouserProfileInfo');



//   useEffect(()=>{
// return()=>{
//   setComapanies({})
// }
//   },[])
  return (
    <Stack spacing={3}>
      <Heading fontSize="18px" fontWeight="600" color="brandPrimary.500">
        Publish as
      </Heading>
      <VStack gap={3}>
        <PublishUserCompanyCard
          profile={userProfileInfo}
          isCompany={false}
          isSelected={selectedUser}
          setValue={(e) => {
            handleChangeArticle(e);
          }}
        />
        <VStack gap={3} maxH="200px" overflow="auto" w="100%">
          {!loading ? (
            companyPageList?.length && companyPageList ? (
              companyPageList?.map((company, i) => (
                <PublishUserCompanyCard
                  key={i}
                  profile={company}
                  isCompany={true}
                  isSelected={selectedUser}
                  setValue={(e) => {
                    handleChangeArticle(e, company);
                  }}
                />
              ))
            ) : (
              <></>
            )
          ) : (
            <SectionLoader />
          )}
        </VStack>
      </VStack>
      {userProfileInfo?.is_staff && (
        <>
          <hr />
          <VStack alignItems="flex-start" mb={3} w="100%">
            <Heading fontSize="18px" fontWeight="600" color="brandPrimary.500">
              Publish to
            </Heading>
            <RadioGroup
              onChange={(e) => handleToChange(e)}
              defaultValue={publishTo}
              w="100%"
            >
              <HStack justifyContent="space-between" w="100%" mb={3}>
                <HStack gap={3}>
                  <Box bg="brandGray.300" p={3} borderRadius="4px">
                    <IndividualArticleIcon />
                  </Box>
                  <Text fontWeight="medium" color="black">
                    Individual article
                  </Text>
                </HStack>
                <Radio value="1" />
              </HStack>
              {userProfileInfo?.is_staff && (
                <>
                  <HStack justifyContent="space-between" w="100%" mb={3}>
                    <HStack gap={3}>
                      <Box bg="brandGray.300" p={3} borderRadius="4px">
                        <MyBridgeArticleIcon />
                      </Box>
                      <Text fontWeight="medium" color="black">
                        Admin (myBridge article)
                      </Text>
                    </HStack>
                    <Radio value="3" />
                  </HStack>
                  <HStack justifyContent="space-between" w="100%" mb={3}>
                    <HStack gap={3}>
                      <Box bg="brandGray.300" p={3} borderRadius="4px">
                        <MyBridgeMewsIcon />
                      </Box>
                      <Text fontWeight="medium" color="black">
                        Admin (myBridge news)
                      </Text>
                    </HStack>
                    <Radio value="2" />
                  </HStack>
                </>
              )}
            </RadioGroup>
          </VStack>{' '}
        </>
      )}
      {!isPageComp && (
        <HStack justifyContent="flex-end" gap={3}>
          <Button variant="outline" onClick={() => handleNextStep()}>
            + Create Article
          </Button>

          <Button variant="primary" onClick={() => handleSave()}>
            Publish as {publishAs ?? userProfileInfo?.first_name}
          </Button>
        </HStack>
      )}
    </Stack>
  );
};

export default PublishAsComponent;
