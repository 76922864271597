/* eslint-disable @nx/enforce-module-boundaries */
import { Avatar, HStack, Text } from '@mybridge/ui';
import React from 'react';
import { Radio } from '@mybridge/ui';

const PublishUserCompanyCard = ({
  profile,
  isCompany,
  isSelected,
  setValue,
  ...props
}) => {
  const name = isCompany
    ? profile.name
    : `${profile.first_name} ${profile.last_name}`;
  return (
    <HStack justifyContent="space-between" w="100%">
      <HStack gap={3}>
        <Avatar
          name={name}
          src={isCompany ? profile.image : profile.profile_pic}
        />
        <Text>{name}</Text>
      </HStack>
      <Radio
        name="posting-as"
        isChecked={isSelected == profile?.id ? true : false}
        value={profile?.id}
        onChange={(e) => setValue(e.target.value)}
      />
    </HStack>
  );
};

export default PublishUserCompanyCard;
